<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
      <notification-emergency
        v-if="permission.emergencyRead"
        class="d-none d-lg-block"
      />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ nama }}
            </p>
            <span class="user-status">{{ role }}</span>
          </div>
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { auth } from '@/firebase'
import permission from '@/permission'
import NotificationEmergency from './NotificationEmergency.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    DarkToggler,
    NotificationEmergency,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      permission: permission.access,
      nama: '',
      role: '',
    }
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.nama = userData.nama !== null ? userData.nama : 'John Doe'
    this.role = userData.role !== null ? userData.role : 'Hacker'
  },
  methods: {
    async logout() {
      await auth.signOut()
      localStorage.removeItem('userData')
      localStorage.removeItem('reloaded')
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Logout berhasil',
          icon: 'EditIcon',
          variant: 'success',
        },
      })

      // Redirect to login page
      this.$router.replace({ name: 'login' })
    },
    adduser() {
      this.$router.push({ name: 'mbahtambahtambahuser' })
    },
  },
}
</script>
