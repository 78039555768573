<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
  >
    <template #button-content>
      <feather-icon
        badge="1"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Emergency call
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          6 Teratas
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notif in notifList"
        :key="notif.loc"
        @click="pushToOrder(notif.order_id, notif.id)"
      >
        <b-media>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <p class="m-0">
                <span class="font-weight-bolder">
                  {{ notif.nama_mitra }}
                </span>
              </p>
              <small class="notification-text">{{ notif.no_hp_mitra }}</small>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="text-right"
            >
              <b-badge
                pill
                :variant="notif.status_read === true ? 'success' : 'danger '"
              >
                {{ notif.status_read === true ? 'Terbaca' : 'Belum terbaca' }}
              </b-badge>
            </b-col>
          </b-row>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <div class="d-flex justify-content-center m-1">
        <b-button
          v-b-modal.modal-center
          variant="primary"
          block
        >Lihat lebih banyak</b-button>
      </div>
    </li>

    <b-modal
      id="modal-center"
      centered
      title="Emergency Call"
    >
      <b-link
        v-for="notifed in notifAllList"
        :key="notifed.loc"
        @click="pushToOrder(notifed.order_id, notifed.id)"
      >
        <b-media>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <p class="m-0">
                <span class="font-weight-bolder">
                  {{ notifed.nama_mitra }}
                </span>
              </p>
              <small class="notification-text">{{ notifed.no_hp_mitra }}</small>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="text-right"
            >
              <b-badge
                pill
                :variant="notifed.status_read === true ? 'success' : 'danger '"
              >
                {{ notifed.status_read === true ? 'Terbaca' : 'Belum terbaca' }}
              </b-badge>
            </b-col>
          </b-row>
        </b-media>
      </b-link>
    </b-modal>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BButton,
  BRow, BCol, BModal, VBModal,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { db } from '@/firebase'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    VuePerfectScrollbar,
    BButton,
    BRow,
    BCol,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      notifList: [],
      notifAllList: [],
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 40,
      wheelPropagation: false,
    }
    return {
      perfectScrollbarSettings,
    }
  },
  created() {
    this.getNotification()
    this.getAllNotification()
  },
  methods: {
    getNotification() {
      db.collection('emergency').limit(6).orderBy('waktu', 'desc').get()
        .then(snaps => {
          const notList = []
          snaps.docs.forEach(doc => {
            notList.push({
              id: doc.id,
              nama_mitra: doc.data().nama_mitra,
              no_hp_mitra: doc.data().no_hp_mitra,
              order_id: doc.data().order_id,
              status_read: doc.data().status_read,
            })
          })
          this.notifList = [...new Set(notList)]
        })
    },
    getAllNotification() {
      db.collection('emergency').orderBy('waktu', 'desc').get()
        .then(snaps => {
          const notList = []
          snaps.docs.forEach(doc => {
            notList.push({
              id: doc.id,
              nama_mitra: doc.data().nama_mitra,
              no_hp_mitra: doc.data().no_hp_mitra,
              order_id: doc.data().order_id,
              status_read: doc.data().status_read,
            })
          })
          this.notifAllList = [...new Set(notList)]
        })
    },
    pushToOrder(orderId, id) {
      db.collection('emergency').doc(id).update({
        status_read: true,
      }).then(() => {
        this.getNotification()
        this.$router.push({ name: 'DetailPesanan', params: { id: orderId } })
      })
    },
  },
}
</script>

<style>

</style>
