<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span class="brand-logo">
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                />
              </span>
              <h2 class="brand-text">
                {{ appName }}
              </h2>
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <br>

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import permission from '@/permission'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      navMenuItems: [],
    }
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
  created() {
    this.navMenuItems = [
      {
        title: 'Dashboard',
        route: 'home',
        icon: 'HomeIcon',
        visible: permission.access.dashboardRead,
      },
      {
        title: 'Mitra',
        route: 'mitra',
        icon: 'UsersIcon',
        visible: permission.access.mitraRead,
      },
      {
        title: 'Leader',
        route: 'leader',
        icon: 'UserIcon',
        visible: permission.access.leaderRead,
      },
      {
        title: 'Customer',
        route: 'customer',
        icon: 'HeartIcon',
        visible: permission.access.customerRead,
      },
      {
        title: 'Pesanan',
        route: 'pesanan',
        icon: 'LayoutIcon',
        visible: permission.access.pesananRead,
      },
      {
        title: 'Layanan',
        route: 'layanan',
        icon: 'MenuIcon',
        visible: permission.access.layananRead,
      },
      {
        title: 'Region',
        route: 'region',
        icon: 'MapIcon',
        visible: permission.access.regionRead,
      },
      {
        title: 'Kabar Mecare',
        route: 'kabar',
        icon: 'InboxIcon',
        visible: permission.access.kabarRead,
      },
      {
        title: 'Withdraw',
        route: 'withDraw',
        icon: 'TrendingDownIcon',
        visible: permission.access.withdrawRead,
      },
      {
        title: 'WhatsApp',
        route: 'whatsapp-api',
        icon: 'PhoneCallIcon',
        visible: permission.access.whatsappRead,
      },
      {
        title: 'Reservasi',
        route: 'reservasi',
        icon: 'BookmarkIcon',
        visible: permission.access.reservasiRead,
      },
      {
        title: 'Promo',
        route: 'promo',
        icon: 'PieChartIcon',
        visible: permission.access.promoRead,
      },
      {
        title: 'SQ Area',
        route: 'sq-area',
        icon: 'UmbrellaIcon',
        visible: permission.access.sqRead,
      },
    ]
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
